<template>
  <div class="result">
    <div v-if="isLoading && page === 1" class="result__loading">
      <p class="result__loading--heading">Đang tìm kiếm</p>
      <div class="result__loading--content">
        <BaseImg
          v-for="i in sizeItemLoading"
          :key="i"
          :src="`${PATH_BRAND}default.webp`"
        />
      </div>
    </div>

    <div v-else-if="listData.length" class="result__list">
      <p class="result__list--heading">{{ totalGame }} kết quả "<span class="key_word">{{ keywordSearch }}</span>"</p>
      <div class="result__list--games">
        <CommonGamesItemGame
          v-for="(item, index) in listData"
          :key="index"
          :item="item"
          show-jackpot
          :show-tag="false"
        />
        <ClientOnly>
          <InfiniteLoading
            v-if="listData.length < totalGame"
            :distance="200"
            @infinite="($state) => $emit('load-more', $state)"
          >
            <template #spinner>
              <span />
            </template>
            <template #complete>
              <span />
            </template>
          </InfiniteLoading>
        </ClientOnly>
      </div>
    </div>
    <div v-else class="result__no-result">
      <p v-if="keywordSearch !== ''" class="text">Không tìm thấy kết quả cho “<span class="key_word">{{ keywordSearch }}</span>”</p>
      <SuggestedKeywords :list-data="suggestedKeywords" :on-press="onPressKeyword" />
    </div>
  </div>
</template>

<script setup lang="ts">
import InfiniteLoading from 'v3-infinite-loading'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import BaseImg from '~/components/common/base-img.vue'
import SuggestedKeywords from '~/components/desktop/modals/search/suggested-keyword/index.vue'
import { PATH_BRAND } from '~/constants/path'

const sizeItemLoading = ref(5)

const props = defineProps({
  listData: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: true
  },
  keywordSearch: {
    type: String,
    default: ''
  },
  totalGame: {
    type: Number,
    default: 0
  },
  page: {
    type: Number,
    default: 0
  },
  suggestedKeywords: {
    type: Array,
    default: () => []
  },
  onPressKeyword: {
    type: Function,
    default: () => {}
  }
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/modals/search/result/index.scss"></style>
