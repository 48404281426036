<template>
  <footer id="footer" class="section-footer">
    <div class="container-custom">
      <div v-if="!hiddenSeo" class="section-footer__seo">
        <nuxt-link :to="PAGE_URLS.HOME">
          <BaseImg :relative-src="LOGO_SITE_IMAGE" class="section-footer__seo--logo" alt="footer logo" @click="navigateToHome" />
        </nuxt-link>
        <div class="section-footer__seo--content">
          <div class="title" v-html="dataSeo?.content_title" />
          <div class="desc" v-html="dataSeo?.content_description" />
        </div>
      </div>
    </div>
    <div class="container-custom">
      <div class="section-footer__nav">
        <div class="section-footer__nav--content">
          <h3 class="title">VỀ CHÚNG TÔI</h3>
          <div class="menus">
            <div v-for="(cols, index) in FOOTER_INFORMATION" :key="index" class="menu-column">
              <nuxt-link v-for="(item, idx) in cols" :key="idx" :to="item.link" class="item">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
        </div>
        <div class="section-footer__nav--content">
          <h3 class="title">DANH MỤC TRÒ CHƠI</h3>
          <div class="menus">
            <div v-for="(cols, index) in FOOTER_GAMES" :key="index" class="menu-column">
              <nuxt-link v-for="(item, idx) in cols" :key="idx" :to="item.link" class="item">
                {{ item.title }}
              </nuxt-link>
            </div>
          </div>
        </div>
        <div class="section-footer__nav--content">
          <p class="title">PHƯƠNG THỨC THANH TOÁN</p>
          <div class="menus-payment-method">
            <a
              v-for="(item, idx) in footerPaymentMethods"
              :id="item.title"
              :key="idx"
              :href="item.jsStatus ? item.link : ''"
              @click.prevent="handleClickMethod(item, $event)"
            >
              <div v-if="!isLogged" class="item-payment-method">
                <BaseImg class="item-payment-method__icon" :src="item.icon" :alt="item.title" />
                <span>{{ item.title }}</span>
              </div>
              <div v-else-if="isLogged && !item.isMaintain" class="item-payment-method">
                <BaseImg class="item-payment-method__icon" :src="item.icon" :alt="item.title" />
                <span>{{ item.title }}</span>
              </div>
              <div v-else class="item-payment-method" :class="{ maintain: item.isMaintain }">
                <BaseImg class="item-payment-method__icon" :src="item.icon" :alt="item.title" />
                <span class="">{{ item.title }}</span>
                <div v-if="item.isShowTooltip" class="tooltip">
                  <div class="tooltip-content">
                    <span class="description">Đang bảo trì</span>
                  </div>
                  <div class="arrow-down" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section-footer__bottom">
      <BaseImg src="/assets/images/components/desktop/footer/bg.webp" alt="bg-contact" />
      <div class="content">
        <div class="contact">
          <nuxt-link :to="`${congdongUrl}`" target="_blank" class="contact__item">
            <BaseImg src="/assets/images/components/common/telegram.svg" alt="telegram" />
            <p class="contact__item-title">Cộng đồng {{ $config.public.BRANCH_NAME }}</p>
          </nuxt-link>
          <nuxt-link :to="`${cskhUrl}`" target="_blank" class="contact__item">
            <BaseImg src="/assets/images/components/common/people.svg" alt="people" />
            <p class="contact__item-title">@CSKH{{ $config.public.BRANCH_NAME }} (Telegram 24/7)</p>
          </nuxt-link>
        </div>
        <div class="copyright">{{ FOOTER_CONTENT.COPY_RIGHT }}</div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { getFooterContent } from '~/constants/footer'
import useModal from '~/composables/useModal'
import { PAGE_URLS } from '~/config/page-url'
import { MODAL_TYPES } from '~/config/constant'
import { useAccountStore } from '~/store/account'
import { LOGO_SITE_IMAGE } from '~/constants/menu'
import { IContact } from '~/types/seo'

const route = useRoute()
const router = useRouter()
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, seoData, isLogged } = storeToRefs(store)
const { openModalWithNavigate } = useModal()
const { FOOTER_CONTENT, FOOTER_INFORMATION, FOOTER_GAMES, FOOTER_PAYMENT_METHOD: FOOTER_PAYMENT_METHOD_RAW } =
  getFooterContent()

const FOOTER_PAYMENT_METHOD = FOOTER_PAYMENT_METHOD_RAW.map((item) => ({
  ...item,
  isShowTooltip: false,
  jsStatus: false
}))

const accountStore: any = useAccountStore()
interface FooterPaymentMethod {
  name?: string;
  title: string;
  key: string;
  link: string;
  icon: string;
  isMaintain: boolean;
  isShowTooltip: boolean;
}
const footerPaymentMethods = ref<FooterPaymentMethod[]>([...FOOTER_PAYMENT_METHOD])

const dataSeo = computed(() => {
  if (route.path === '/') {
    return seoData.value.find((item) => item.alias === '/')
  }
  if (route.name === 'tin-tuc-alias') {
    const seoItem = seoData.value.find((item) => item.alias === '/')
    if (seoItem) {
      seoItem.content_title = seoItem.content_title.replace(/<\/?h1>/g, (match) => (match[1] === '/' ? '</h2>' : '<h2>'))
      seoItem.content_description = seoItem.content_description.replace(/<\/?h2>/g, (match) => (match[1] === '/' ? '</h3>' : '<h3>'))
    }
    return seoItem
  }
  if (route.name === 'khuyen-mai-id') {
    return seoData.value.find((item) => item.alias === 'khuyen-mai')
  }
  return seoData.value.find((item) => item.alias === route.path.slice(1)) ?? seoData.value.find((item) => item.alias === '/')
})

const updateFooterPaymentMethods = () => {
  footerPaymentMethods.value = FOOTER_PAYMENT_METHOD.map((item) => {
    let isMaintain = false
    const jsStatus = true
    if (!accountStore.loading) {
      switch (item.key) {
        case 'cardList':
          isMaintain = !(
            accountStore.cardList?.VIETTEL?.status === 1 ||
            accountStore.cardList?.MOBIFONE?.status === 1 ||
            accountStore.cardList?.VINAPHONE?.status === 1 ||
            accountStore.cardList?.ZING?.status === 1
          )
          break
        case 'ViettelPays':
          isMaintain = accountStore.viettelPays?.length === 0
          break
        case 'nicepayBanks':
          isMaintain =
            (isEmpty(accountStore.providerGoPay) ||
              !accountStore.providerGoPay?.GOPAY?.bankings?.length) &&
            accountStore[item.key]?.length === 0
          break
        case 'crypto_v2':
          isMaintain = accountStore.crypto_v2?.[item.key]?.length === 0
          break
        case 'momos':
          isMaintain = !accountStore.momos || accountStore.momos.length === 0
          break
        default:
          break
      }
    }
    return {
      ...item,
      isMaintain,
      jsStatus
    }
  })
}

const { fetchBranchContact } = useContact()
const { data: contacts } = await useAsyncData('contacts', async () => {
  return await fetchBranchContact()
})
const cskhUrl = computed(() => contacts.value?.find((item: IContact) => item.name === 'cskh')?.url)
const congdongUrl = computed(() => contacts.value?.find((item: IContact) => item.name === 'congdong')?.url)

onMounted(() => {
  updateFooterPaymentMethods()
})
const navigateToHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  if (route.fullPath === '/') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}

const handleClickMethod = (item: any, event: any) => {
  if (currentUser.value === null && !item.isMaintain) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else if (currentUser.value === null && item.isMaintain) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', '')
  } else if (!item.isMaintain) {
    navigateTo(item?.link)
  } else {
    item.isShowTooltip = true
    setTimeout(() => {
      item.isShowTooltip = false
    }, 2000)
    event.preventDefault()
  }
}

watch(isLogged, (newVal) => {
  if (newVal) {
    updateFooterPaymentMethods()
  }
})

watch(
  () => [
    accountStore.cardList,
    accountStore.viettelPays,
    accountStore.providerGoPay,
    accountStore.crypto_v2,
    accountStore.momos,
    accountStore.loading
  ],
  () => {
    updateFooterPaymentMethods()
  },
  { deep: true }
)

</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/footer/index.scss" />
