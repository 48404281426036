<template>
  <div class="user-not-login">
    <BaseButton
      id="login_btn"
      class="btn-login base-button--btn-transparent"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'login')"
    >
      Đăng Nhập
    </BaseButton>
    <BaseButton
      id="register_btn"
      class="btn-register base-button--btn-primary"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')"
    >
      Đăng Ký
    </BaseButton>
  </div>
</template>
<script setup>
import BaseButton from '~/components/common/base-button'
import { useHeader } from '~/composables/useHeader'

const { onClickAuthor, MODAL_TYPES } = useHeader()
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/header/user-not-login.scss" />
