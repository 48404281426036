import { PAGE_URLS, ACCOUNT_URLS, LOBBY_GAME_URLS, BASE } from '~/config/page-url'
const PATH_IMAGE = '/assets/images/components/desktop/footer/'

export function getFooterContent() {
  const { $config } = useNuxtApp()

  const FOOTER_INFORMATION = [
    [
      { title: 'Giới thiệu', link: `${PAGE_URLS.INTRODUCTION}/nha-cai` },
      { title: 'Điều khoản', link: PAGE_URLS.POLICY }
    ],
    [
      { title: 'Bảo mật ', link: PAGE_URLS.SECURITY },
      { title: 'Trợ giúp', link: PAGE_URLS.GUIDE_REGISTER }
    ],
    [
      { title: 'Khuyến mãi', link: PAGE_URLS.PROMOTION },
      { title: 'Tin tức', link: PAGE_URLS.NEWS }
    ]
  ]

  const FOOTER_GAMES = [
    [
      { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
      { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME }
    ],
    [
      { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
      { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE }
    ],
    [
      { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
      { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO }
    ],
    [
      { title: 'Game nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES },
      { title: 'Game bài', link: LOBBY_GAME_URLS.GAME_BAI }
    ]
  ]

  const FOOTER_PAYMENT_METHOD = [
    {
      name: 'Ngân Hàng',
      title: 'Codepay',
      key: 'nicepayBanks',
      link: ACCOUNT_URLS.DEPOSIT_DA,
      icon: `${PATH_IMAGE}bank.svg`,
      isMaintain: false,
      jsStatus: true
    },
    {
      title: 'P2P',
      key: 'buy',
      link: ACCOUNT_URLS.P2P,
      icon: `${PATH_IMAGE}p2p.svg`,
      isMaintain: false,
      jsStatus: true

    },
    {
      title: 'Tiền ảo',
      key: 'crypto_v2',
      link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
      icon: `${PATH_IMAGE}crypto.svg`,
      isMaintain: false,
      jsStatus: true

    },
    {
      title: 'Momo',
      key: 'momos',
      link: ACCOUNT_URLS.DEPOSIT_MOMO,
      icon: `${PATH_IMAGE}momo.svg`,
      isMaintain: false,
      jsStatus: true

    },
    {
      title: 'Viettel Money',
      key: 'ViettelPays',
      link: ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY,
      icon: `${PATH_IMAGE}viettel-money.svg`,
      isMaintain: false,
      jsStatus: true

    },
    {
      title: 'Thẻ cào',
      key: 'cardList',
      link: ACCOUNT_URLS.DEPOSIT_CARD,
      icon: `${PATH_IMAGE}card.svg`,
      isMaintain: false,
      jsStatus: true
    }
  ]

  const LIST_ROUTE_HIDE_SEO = [null]

  const FOOTER_CONTENT = {
    SLOGAN_TITLE: `${$config.public.BRANCH_NAME}  - Nhà cái thể thao trực tuyến, Siêu chợ games đổi thưởng đỉnh cao`,
    SLOGAN_DESCRIPTION: `${$config.public.BRANCH_NAME} là trang cá cược thể thao hấp dẫn, cung cấp đa dạng về sản phẩm trò chơi như Thể Thao, Trò Chơi, Casino Trực Tuyến và thưởng hoàn trả cao nhất trên thị trường`,
    VIEW_MORE: 'xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.public.BRANCH_NAME} All Rights Reserved.`
  }

  return {
    FOOTER_INFORMATION,
    FOOTER_GAMES,
    FOOTER_PAYMENT_METHOD,
    FOOTER_CONTENT,
    LIST_ROUTE_HIDE_SEO
  }
}
